@keyframes SCALE_IN {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes FADE_IN {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes FADE_IN_Y {
  0% {
    opacity: 0;
    transform: translateY(.5rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0rem);
  }
}
