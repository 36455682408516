

@mixin btn-black {
  color: #fff;
  background: #000;
  transition: 500ms ease;

  &:hover {
    background-color: $btn-hover-grey !important;
  }
}

@mixin aspect-ratio-media($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: ‘’;
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  [data-media] {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    // TODO: remove this if we do not need browser support
    // font-family: ‘object-fit: cover;’;
  }
}