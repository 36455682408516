@import './variables';
@import './globals';
@import './animations';
@import './mixins';

// global styles file
#root {
  // background-color: $light-background;
  max-height: 100vh;
  min-height: 100vh;
  // min-height: -webkit-fill-available;
  
  display: flex;
  .App {
    display: flex;
    flex-direction: column;
    
    max-height: 100%;
    min-height: 100%;
    overflow: hidden;
    width: 100%;
    @media screen and (min-width: 1024px) {
      padding: 0.9rem;
    }
    // min-height: -webkit-fill-available;
  }
}
body {
  height: auto !important;
}
* {
  outline: none;
}
