@import 'variables.scss';
@import 'mixins.scss';

.root {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100vh;
  // background: $light-background;
  padding: 0.9rem;
  > div {
    gap: .8rem;
    width: 100% !important;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    & > div {
      width: 100%;
      height: 100%;
      &:nth-child(1) {
        display: none;
      }
    }
    @media screen and (min-width: 1024px) {
      flex-direction: row;
      & > div {
        width: 50%;
        &:nth-child(1) {
          display: flex;
        }
      }
    }
  }
  :global {
    .amplify-button[data-variation='primary'] {
      background-color: transparent;
      color: black;
      border: 1px solid #000000;
      border-radius: 0.9rem;
      font-size: 1.8rem;
      font-weight: 400;
      padding: 1.3rem 0;
      &:hover {
        color: white;
        background-color: black;
      }
    }
    input {
      border: none;
    }
    [data-amplify-router] {
      border: none;
      box-shadow: none;
      background-color: white;
      border-radius: 1.5rem;
      overflow: hidden;
      & > div {
        height: 100%;
        div:nth-child(2) {
          border-top-left-radius: 0 !important;
        }
        div:nth-child(3) {
          border-top-right-radius: 0 !important;
        }
      }
    }
    div[role='tablist'] {
      background-color: white;
    }
    div[role="tabpanel"] {
      border-top-left-radius: .9rem;
      border-top-right-radius: .9rem;
      background: rgba(182, 202, 209, 0.5);
    }
    div[role="tabpanel"][data-state="active"] {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      [data-amplify-form] {
        padding: 0;
        width: 80vw;
        max-width: 27rem;
      }
      .amplify-field > div {
        background-color: white;
        border-radius: .9rem !important;
        input {
          border-radius: .9rem;
        }
        overflow: hidden;
      }
      .amplify-selectfield {
        .amplify-select__wrapper {
          border-radius: 0.9rem !important;
        }
      }
    }
    button[type='submit'] {
      @include btn-black;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px white inset !important;
        border: none;
    }
    button,
    input {
      height: 6rem;
      font-size: 1.6rem;
      line-height: 1;
      font-family: 'Nexa';
      outline: none;
      box-shadow: none !important;
    }
    
    select {
      height: 4rem;
      font-size: 1.6rem;
      line-height: 2;
      font-family: 'Nexa';
      border: none;
    }
    .amplify-field__error-message {
      font-family: 'Nexa';
    }
    .amplify-text.amplify-checkbox__label {
      margin-left: 0.9rem;
      font-family: 'Nexa';
    }
    button.amplify-button[data-size='small'] {
      font-size: 1.4rem;
    }

    .amplify-tabs {
      border: none;
      button {
        background-color: white;
        border-top-right-radius: 1.5rem;
        border-top-left-radius: 1.5rem;
        font-size: 1.8rem;
        // border-bottom: 1px solid $border-grey;
      }
    }
    .amplify-tabs-item {
      border: none;
      margin: 0;
      color: black;
      font-weight: 400;
      transition: 400ms ease;
      &[data-state='active'] {
        background: rgba(182, 202, 209, 0.5);
      }
    }
    input,
    .amplify-field__show-password {
      border-color: black;
      outline-color: transparent;
      border-color: $border-grey;
    }
    .amplify-button[data-variation='link'] {
      color: black;
      &:hover {
        text-decoration: underline;
        background-color: transparent;
      }
    }
    .amplify-heading {
      padding-bottom: 2rem;
    }
    .amplify-alert[data-variation=error] {
      height: 4rem;
    }
    .amplify-heading {
      font-size: 2rem !important;
    }
    .amplify-text {
      font-size: 1.4rem !important;
    }
  }
}
