@font-face {
  font-family: 'Nexa';
  src: url('../assets/fonts/NexaLight.woff2') format('woff2'),
    url('../assets/fonts/NexaLight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nexa';
  src: url('../assets/fonts/NexaBold.woff2') format('woff2'),
    url('../assets/fonts/NexaBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Nexa';
  src: url('../assets/fonts/NexaRegular.woff2') format('woff2'),
    url('../assets/fonts/NexaRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.container {
  padding-left: 40px;
  padding-right: 40px;
}

html {
  font-size: 10px;
}

button,
input {
  outline: none;
  -webkit-appearance: none;
  box-shadow: none;
}

div {
  position: relative;
}
